import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border-img: url(border.png);
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  border: none;
  border-radius: 100%;
  width: 200px;
  @media (min-width: 1000px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: rgb(0, 0, 255, 1);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    const now = +new Date();
    if (now < 1656428400000) {
      setFeedback('Its not time to start');
      return;
    }
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`WOW, the ${CONFIG.NFT_NAME} is yours!`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <body>
      <div id="app">
        <header>
          <img src="/config/images/ANYPAD_LOGO.svg" />
        </header>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example.svg"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              //backgroundColor: "rgb(0,0,0,90%)",
              backgroundSize: "100% 100%",
              padding: 15,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {/* <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {data.cost/Math.pow(10,18)}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle> */}
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  0.2 BNB for the first 50NFTs, after that 0.4 BNB
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example.svg"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <section class="hero">
          <div class="hero__text">
            <p class="highlight_text hero__text__main">
              500 unique NFTs that will allow investors to access premium
              blockchain startup investments in the Anypad ecosystem.
            </p>
            </div>
          <div class="hero__image">
            <img src="/config/images/hero_img.svg" />
          </div>
        </section>
        <section class="info">
          <div class="info__title">WHY ANYPAD ?</div>
          <div class="info__text">
            Enjoy the full
            potential of Anypad NFTs and invest early in prestigious blockchain
            firms within the Anypad ecosystem. You can access ACE BLOCKCHAIN
            STARTUPS with Anypad NFTs and:
          </div>
          <div class="sponsor">
            <div class="sponsor__bg"></div>
            <div class="sponsor__item">
              <img src="/config/images/sponsor_item_bullet.svg" />
              No loss on
              <br />
              your Investment
            </div>
            <div class="sponsor__item">
              <img src="/config/images/sponsor_item_bullet.svg" />
              Zero Rug
              <br />
              Pulls.
            </div>
            <div class="sponsor__item">
              <img src="/config/images/sponsor_item_bullet.svg" />
              No
              <br />
              Frauds
            </div>
            <div class="sponsor__item">
              <img src="/config/images/sponsor_item_bullet.svg" />
              Exclusively 5-Star
              <br />
              projects
            </div>
          </div>
        </section>
        <section class="details">
          <div class="details__item">
            <div class="details__item__title">
              <div>
                <img src="/config/images/nft.svg" />
              </div>
              <p class="highlight_text">What are NFTs?</p>
            </div>
            <div class="details__item__info">
              <p>Non-Fungible Tokens (NFTs)</p>
              <div>
                NFTs are unique digital assets created on the blockchain. They
                can be everything from gaming items and digital art, to sports
                collectibles and real-world assets. Non-fungible tokens are
                distinct in their own right, and they are available in a variety
                of sizes and shapes.
              </div>
              <div>
                The most well-known NFT use is digital art, but the ecosystem is
                also seeing rapid growth in blockchain games and metaverse land.
                Through collaborations to create bespoke avatars or open up shop
                in a virtual environment, brands are also embracing the trend.
              </div>
            </div>
          </div>
          <div class="details__item">
            <div class="details__item__title">
              <div>
                <img src="/config/images/shield.svg" />
              </div>
              <p class="highlight_text">What are Anypad Shield NFTs?</p>
            </div>
            <div class="details__item__info">
              <p>Anypad Shield Non-Fungible Tokens (NFTs)</p>
              <div>
                Anypad Shield NFTs are exclusive digital assets created on the
                blockchain. There are 500 unique NFTs that will allow investors
                access to premium blockchain startup investment in the Anypad
                ecosystem. Anypad Shield NFTs will give you
              </div>
              <div class="details__grid">
                <div class="details__grid__item">
                  <img src="/config/images/protec.svg" />
                  100% Loss Protection
                </div>
                <div class="details__grid__item">
                  <img src="/config/images/invest.svg" />
                  Rugpulls free investing
                </div>
                <div class="details__grid__item">
                  <img src="/config/images/nofraud.svg" />
                  No Fraud startups
                </div>
                <div class="details__grid__item">
                  <img src="/config/images/star.svg" />
                  Exclusive 5-star investments
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="features">
          <p class="features__title">
            <span class="highlight_text">Everything you need,</span>
            <span class="underline">
              <span class="highlight_text">in one place</span>
            </span>
          </p>
          <p class="features__subtitle">
            Premium Investing with Anypad Shield NFTs
          </p>
          <div>
            Are you looking to utilize NFTs? Great! You'll need a platform to
            mint them, a marketplace to list them, and a wallet to help users
            store, trade and manage them.
          </div>
          <div>
            Anypad offers the end-to-end solution required to invest in
            specialized blockchain businesses that support the creation of new
            revenue streams, the rapid expansion of your portfolio and give you
            the next 100x return.
          </div>
        </section>
        <section class="footer">
          <div class="footer__title">
            <img src="/config/images/footer.svg" />
            <p class="highlight_text">
              Exclusive Features of Anypad Shield NFTs
            </p>
            </div>
          <div class="footer__content">
            <div class="footer__content__item">
              <img src="/config/images/chart.svg" />
              Zero loss on premium investments.
            </div>
            <div class="footer__content__item">
              <img src="/config/images/nftbadge.svg" />
              All NFTs are resellable and will be available on the marketplace.
            </div>
            <div class="footer__content__item">
              <img src="/config/images/stake.svg" />
              You can stake your NFT and earn an attractive APR.
            </div>
            <div class="footer__content__item">
              <img src="/config/images/secure.svg" />
              Anypad NFTs will guarantee you airdrop from partner brands and
              more.
            </div>
            <div class="footer__content__item">
              <img src="/config/images/growth.svg" />
              Double your allocation size for Shield Projects.
            </div>
            <div class="footer__content__item">
              <img src="/config/images/refunds.svg" />
              Get instant refunds of your investments.
            </div>
          </div>
        </section>
      </div>
    </body>
  );
}

export default App;
